import { Helmet } from 'react-helmet'
import { Box, Flex } from 'grid-styled'
import * as React from 'react'
import Layout from '../components/Layout'
import { graphql, Link } from 'gatsby'
import Img from 'gatsby-image'

import CTA from '../components/CTA'
import Header from '../components/Header'
import {
  SplitContent,
  SplitFigure,
  Container,
  SplitSection,
  ReadMore,
  Text
} from '../components/styles'
import Arrow from '../components/Arrow'
import styled from 'styled-components'
import Hero from '../components/Hero'
import { AboutContact } from '.'
import Button from '../components/Button'
import BackgroundSection from '../components/BackgroundSection'

const StyledBackgroundSection = styled(BackgroundSection)`
  min-height: 600px;
  @media (max-width: 500px) {
    height: auto;
    padding-bottom: 3rem;
  }
`

const Value = styled(Container).attrs({
  bg: '#fff',
  medium: true,
  shadow: true
})`
  margin-bottom: 1.5rem;
  padding: 3rem;

  > div > div:first-child {
    padding-right: 3rem;
  }
`

const StyledFlex = styled(Flex)`
  @media (max-width: 639px) {
    flex-direction: column;
    > div {
      margin-bottom: 2rem;
    }
  }
`

const StyledHero = styled(Hero)`
  display: flex;
  align-items: center;
  width: 100vw;
  margin: 0;
`
const Info = styled.div`
  z-index: 10;
  background: #fff;
  padding: 1rem 3rem;
  border-radius: 8px;
  margin-top: 30vh;
  text-align: center;
  max-width: 600px;
  h3 {
    color: ${props => props.theme.colors.dark};
    text-shadow: none;
  }
`

/* prettier-ignore */
interface IProps {
  data: {
    coast: {
      childImageSharp: {
        fluid: object
      }
    };
    ecology: {
      childImageSharp: {
        fluid: object
      }
    };
    top: {
      childImageSharp: {
        fluid: object
      }
    };
  }
}

export default ({ data }: IProps) => (
  <Layout header="dark">
    <Helmet>
      <title>About Parinity Financial Planners - Parinity</title>
      <meta
        name="description"
        content="Get to know the our Parinity team, from a variety of backgrounds we offer a unqiue set of perspectives on finances to ensure you always get the best personal, business, and wealth advice."
      />
    </Helmet>

    <Header>
      <h1>Our Team</h1>
    </Header>

    <StyledBackgroundSection
      darkness="0.3"
      style={{ marginTop: 100 }}
      image={data.drSellars.childImageSharp}
      title={'Meet The Parinity Team'}
    >
      <p>
        No matter what we're doing we put people first. Our team is dedicated to
        providing transparent financial advice that is unique to you. We
        understand that some clients want an active role in the management of
        their finances while others just want the piece of mind to know their
        finances are structured to meet their goals, both now and in the future.
      </p>
      <p>
        Our business is built on strong partnerships. Our approach is engaging
        and all encompassing. We explore all aspects of your financial life to
        understand what truly motivates you and your financial decisions. Our
        priority is to recommend the best financial solution for you and then
        work with you to make it happen.
      </p>

      <Link to="/about-us/#team">
        <Button outline={true} color="#fff" small={true}>
          Learn about the team
        </Button>
      </Link>
    </StyledBackgroundSection>

    <Box mt={6} />

    <Container medium={true} id="team">
      <SplitSection>
        <SplitFigure>
          <Img fluid={data.lance.childImageSharp.fluid} />
        </SplitFigure>
        <SplitContent>
          <h2>Lance Cheung CFP®</h2>
          <p>
            Lance is the founding director of Parinity Pty Ltd and the
            Responsible Manager for the licensee company, The Parinity Group Pty
            Ltd. He has been studying and practising in the financial services
            industry since 2001. With a strong focus on client outcomes and
            technical expertise across a wide range of finance specialities,
            Lance takes pride in being his client’s first port of call for all
            their financial needs.
          </p>
          <ul>
            <li>MASTERS IN TAXATION AND FINANCIAL PLANNING (UNSW)</li>
            <li>BACHELOR OF COMMERCE (GRIFFITH)</li>
            <li>CERTIFIED FINANCIAL PLANNER® (CFP®)</li>
            <li>CERT IV IN FINANCIAL SERVICES (MORTGAGE BROKING)</li>
            <li>COMMISSIONER FOR DECLARATIONS</li>
          </ul>
        </SplitContent>
      </SplitSection>
      <SplitSection style={{ marginTop: 50 }}>
        <SplitFigure>
          <Img fluid={data.subashini.childImageSharp.fluid} />
        </SplitFigure>
        <SplitContent>
          <h2>Subashini Sridharan AFP®</h2>
          <p>
            Suba joined Parinity in 2016 after completing her dual Bachelor’s
            degree at Griffith University. Although she’s relatively new to the
            financial services industry, she has more life experiences than most
            at her age. These life experiences in addition to her studies, has
            ignited her continuous drive to learn, grow and help others.
          </p>
          <ul>
            <li> BACHELOR OF COMMERCE (ACCOUNTING)</li>
            <li>BACHELOR OF BUSINESS (HR)</li>
            <li>ASSOCIATE FINANCIAL PLANNER AFP</li>
            <li>COMMISSIONER FOR DECLARATIONS</li>
          </ul>
        </SplitContent>
      </SplitSection>
      <SplitSection style={{ marginTop: 50 }}>
        <SplitFigure>
          <Img fluid={data.adam.childImageSharp.fluid} />
        </SplitFigure>
        <SplitContent>
          <h2>Adam Leigh</h2>
          <p>
            Adam has been working in Financial planning for 3 years and brings
            his own unique perspective to the process. Coming into financial
            planning organically by taking control of his own finances and
            creating the lifestyle he wanted, he is passionate and tries to make
            the process relatable to everyday people preferring a casual
            approach without jargon. Adam practices what he preaches and
            currently travels between his apartment in Thailand, where he works
            remotely through technology, and Australia.
          </p>
          <ul>
            <li> STUDYING BACHELOR OF BUSINESS (FINANCIAL PLANNING)</li>
            <li>ADVANCED DIPLOMA OF CANTONESE AS A SECOND LANGUAGE</li>
          </ul>
        </SplitContent>
      </SplitSection>
    </Container>

    <AboutContact data={data} />

    <CTA
      title="Looking for a financial adviser?"
      subtitle="We’re here to help."
      button="Contact Us"
      inverted={true}
    />
  </Layout>
)

export const query = graphql`
  query AboutPageQuery {
    drSellars: file(relativePath: { eq: "lance-bg.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    lance: file(relativePath: { eq: "lance.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    adam: file(relativePath: { eq: "adam.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    subashini: file(relativePath: { eq: "subashini.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
